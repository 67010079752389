import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const Status = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const ActionsList = styled.ul`
    list-style: circle;
    padding-left: ${({ theme }) => `calc(${theme.box.spacing.xxl} + ${theme.box.spacing.xl})`};
`;

export const ActionsListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.md};
    height: 3rem;
    padding-left: ${({ theme }) => theme.box.spacing.md};
`;

export const ActionsPlaceholder = styled.div`
    padding-left: ${({ theme }) => `calc(${theme.box.spacing.xxl} + ${theme.box.spacing.md})`};
    height: 3rem;
    font-size: ${({ theme }) => theme.font.size.md};
    font-style: italic;
`;
