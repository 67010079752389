import React from 'react';
import { useTranslation } from 'react-i18next';
import { Case } from '../../../../../types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    Modal,
} from '../../../../../components';
import { APIService } from '../../../../../services';
import { useLocale, usePromises } from '../../../../../hooks';
import { formatLocalizedText } from '../../../../../tools';

type Props = {
    onClose: () => void;
    refreshCase: () => Promise<void>;
    currentCase: Case<{ clients: false; employees: false }>;
};

const UpdateCaseStatusModal = ({ currentCase, onClose, refreshCase }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        status: Yup.string().required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            status: currentCase.status.id,
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            if (submittedValues.status === currentCase.status.id) {
                return onClose();
            }
            await executePromise(async () => {
                await APIService.cases().updateCaseStatus(currentCase.id, submittedValues.status);
                await refreshCase();
                onClose();
            });
        },
    });

    return (
        <Modal title={t('generic.update_status')} onClose={onClose} error={error} clearError={clearError}>
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="status" expand>
                    <FormGroupLabel>{t('generic.status')}</FormGroupLabel>
                    <FormSelectInput
                        options={currentCase.category.workflows
                            .filter((workflow) => workflow.isEnabled === true)
                            .map((workflow) => ({
                                value: workflow.status.id,
                                label: formatLocalizedText(workflow.status.locale, locale),
                            }))}
                    />
                </FormGroup>
                <FormFooter submitLabel={t('generic.save')} secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default UpdateCaseStatusModal;
