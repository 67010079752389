import React from 'react';
import { Routes } from '../../routes';
import { Navbar } from '../../elements';
import { Outlet } from 'react-router-dom';
import { Container, Content } from './CategoriesPages-styles';

const CategoriesPages = () => {
    const list = [
        {
            id: 'categories',
            path: Routes.CATEGORIES_LIST,
            translation: 'categories.navigation.categories_list',
        },
        {
            id: 'quotes',
            path: Routes.CATEGORIES_QUOTES_LIST,
            translation: 'categories.navigation.subcategories_list',
        },
        {
            id: 'files',
            path: Routes.CATEGORIES_FILES_LIST,
            translation: 'categories.navigation.files_list',
        },
        {
            id: 'workflow',
            path: Routes.CATEGORIES_WORKFLOW,
            translation: 'categories.navigation.workflow',
        },
    ];

    return (
        <Container className="container">
            <Navbar list={list} />
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
};

export default CategoriesPages;
