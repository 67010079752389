import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    padding: ${({ theme }) => theme.box.spacing.md};
    border-radius: 8px;
    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.xs};
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    user-select: none;
    line-height: 1.4;
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const ActionsList = styled.div`
    padding-left: ${({ theme }) => theme.box.spacing.md};
`;

export const Action = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.box.spacing.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const RemoveAction = styled.div<{ isDisabled: boolean }>`
    svg {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.danger.main};
        font-size: ${({ theme }) => theme.font.size.md};
    }

    ${({ isDisabled, theme }) => {
        if (isDisabled === true) {
            return {
                svg: {
                    cursor: 'not-allowed',
                    color: theme.colors.text.disabled,
                },
            };
        }
    }}
`;

export const AddAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const AddActionLabel = styled.div`
    text-decoration: underline;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
`;
