import React from 'react';
import { Container, Content, Title } from './WorkflowForm-styles';
import WorkflowFormItem from '../WorkflowFormItem';
import { CaseStatus, Locale, StatusListItem } from '../../../../../../types';

export type WorkflowFormItemType = {
    status: StatusListItem;
    isEnabled: boolean;
    actions: Array<{
        title_en: string;
        title_fr: string;
        internalId?: string;
    }>;
};

type Props = {
    form: Array<WorkflowFormItemType>;
    setForm: React.Dispatch<React.SetStateAction<WorkflowFormItemType[]>>;
    isUpdating?: boolean;
};

const WorkflowForm = ({ form, setForm, isUpdating = false }: Props) => {
    const onChangeStatus = (status: CaseStatus, isEnabled: boolean) => {
        setForm((prev) =>
            prev.map((prevStatus) =>
                prevStatus.status.id === status ? { ...prevStatus, isEnabled: isEnabled } : prevStatus
            )
        );
    };

    const onChangeAction = (status: CaseStatus, index: number, locale: Locale, value: string) => {
        const key = locale === Locale.EN ? 'title_en' : 'title_fr';
        setForm((prev) =>
            prev.map((prevStatus) =>
                prevStatus.status.id === status
                    ? {
                          ...prevStatus,
                          actions: prevStatus.actions.map((prevAction, prevIndex) =>
                              prevIndex === index
                                  ? {
                                        ...prevAction,
                                        [key]: value,
                                    }
                                  : prevAction
                          ),
                      }
                    : prevStatus
            )
        );
    };

    const onAddAction = (status: CaseStatus) => {
        setForm((prev) =>
            prev.map((prevStatus) =>
                prevStatus.status.id === status
                    ? {
                          ...prevStatus,
                          actions: [
                              ...prevStatus.actions,
                              {
                                  title_en: '',
                                  title_fr: '',
                              },
                          ],
                      }
                    : prevStatus
            )
        );
    };

    const onRemoveAction = (status: CaseStatus, index: number) => {
        setForm((prev) =>
            prev.map((prevStatus) =>
                prevStatus.status.id === status
                    ? {
                          ...prevStatus,
                          actions: prevStatus.actions.filter(
                              (_, prevActionIndex) => prevActionIndex !== index
                          ),
                      }
                    : prevStatus
            )
        );
    };

    return (
        <Container>
            <Title>Status:</Title>
            <Content>
                {form
                    .filter((item) => (isUpdating === true ? item.isEnabled === true : true))
                    .map((item) => (
                        <WorkflowFormItem
                            item={item}
                            key={item.status.id}
                            onChangeAction={onChangeAction}
                            onAddAction={onAddAction}
                            onRemoveAction={onRemoveAction}
                            onChangeStatus={onChangeStatus}
                            isUpdating={isUpdating}
                        />
                    ))}
            </Content>
        </Container>
    );
};

export default WorkflowForm;
