import styled from 'styled-components';

export const Container = styled.div`
    grid-column: 1 / -1;
`;

export const Title = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const Content = styled.div``;
