import React from 'react';
import {
    Container,
    List,
    ListItem,
    Title,
    Placeholder,
    ListItemLabel,
    ListItemLabelText,
    ListItemLabelBox,
} from './CaseOverviewWorkflows-styles';
import { Case } from '../../../../../../types';
import { CheckboxInput, LocalizedText } from '../../../../../../components';
import { useLocale, useLoggedInUser, usePromises } from '../../../../../../hooks';
import { formatLocalizedText } from '../../../../../../tools';
import { APIService } from '../../../../../../services';
import { useTranslation } from 'react-i18next';

type Props = {
    currentCase: Case;
    refreshCurrentCase: () => Promise<void>;
};

const CaseOverviewWorkflows = ({ currentCase, refreshCurrentCase }: Props) => {
    const { t } = useTranslation();
    const user = useLoggedInUser();
    const locale = useLocale();
    const [{ executePromise }] = usePromises();

    const onChangeWorkflow = async (actionInternalId: string, isCompleted: boolean) => {
        await executePromise(async () => {
            await APIService.cases().updateWorkflows(currentCase.id, {
                actionInternalId: actionInternalId,
                isCompleted: isCompleted,
            });
            await refreshCurrentCase();
        });
    };

    return (
        <Container>
            <Title>{t('generic.workflow')}</Title>
            <List>
                {currentCase.workflows.length === 0 && <Placeholder>{t('generic.no_actions')}</Placeholder>}
                {currentCase.workflows.map((workflow, index) => (
                    <ListItem key={index}>
                        {user.isEmployee() === false ? (
                            <CheckboxInput
                                label={formatLocalizedText(workflow.title, locale)}
                                isChecked={workflow.isCompleted}
                                onChange={(value) => onChangeWorkflow(workflow.internalId, value)}
                            />
                        ) : (
                            <ListItemLabel>
                                <ListItemLabelBox isCompleted={workflow.isCompleted}>
                                    {t(
                                        workflow.isCompleted
                                            ? 'case.overview.workflow.completed'
                                            : 'case.overview.workflow.inProgress'
                                    )}
                                </ListItemLabelBox>
                                <ListItemLabelText>
                                    <LocalizedText text={workflow.title} />
                                </ListItemLabelText>
                            </ListItemLabel>
                        )}
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default CaseOverviewWorkflows;
