import React, { useState } from 'react';
import { usePromises, useToggle } from '../../../hooks';
import { APIService } from '../../../services';
import { PageContainer, PageHeader, PageHeaderTitle } from '../../../components';
import { MdOutlinePendingActions } from 'react-icons/md';
import { CategoriesWorkflowsList, CategoriesWorkflowsAssignation } from './components';
import {
    CreateWorkflowModal,
    UpdateCategoryWorkflowAssignmentModal,
    UpdateCategoryWorkflowModal,
} from './modals';
import { CategoryWorkflow, CategoryWorkflowAssignment } from '../../../types';
import { useTranslation } from 'react-i18next';

enum CustomActions {
    CREATE_WORKFLOW = 'create_workflow',
    UPDATE_WORKFLOW = 'update_workflow',
    DELETE_WORKFLOW = 'delete_workflow',
    UPDATE_WORKFLOW_ASSIGNMENT = 'update_workflow_assignment',
}

const CategoriesStatusActions = () => {
    const { t } = useTranslation();
    const [action, openModal, closeModal] = useToggle<null | CustomActions>(null);
    const [promises, [categoryWorkflows, categoryWorkflowsAssignments]] = usePromises(
        () => APIService.categories().getWorkflowsList(),
        () => APIService.categories().getWorkflowsAssignments()
    );
    const [selectedCategoryWorkflow, setSelectedCategoryWorkflow] = useState<null | CategoryWorkflow>(null);
    const [selectedCategoryWorkflowAssignment, setSelectedCategoryWorkflowAssignment] =
        useState<null | CategoryWorkflowAssignment>(null);

    return (
        <>
            {action === CustomActions.CREATE_WORKFLOW && (
                <CreateWorkflowModal
                    onClose={closeModal}
                    refreshCategoriesWorkflows={categoryWorkflows.refresh}
                    refreshCategoriesWorkflowsAssignments={categoryWorkflowsAssignments.refresh}
                />
            )}

            {action === CustomActions.UPDATE_WORKFLOW && (
                <UpdateCategoryWorkflowModal
                    onClose={closeModal}
                    refreshCategoriesWorkflows={categoryWorkflows.refresh}
                    refreshCategoriesWorkflowsAssignments={categoryWorkflowsAssignments.refresh}
                    selectedCategoryWorkflow={selectedCategoryWorkflow!}
                />
            )}

            {action === CustomActions.UPDATE_WORKFLOW_ASSIGNMENT && (
                <UpdateCategoryWorkflowAssignmentModal
                    onClose={closeModal}
                    categoryWorkflows={categoryWorkflows.data ?? []}
                    refreshCategoriesWorkflowsAssignments={categoryWorkflowsAssignments.refresh}
                    selectedCategoryWorkflowAssignment={selectedCategoryWorkflowAssignment!}
                />
            )}

            <PageContainer error={promises.error} clearError={promises.clearError}>
                <PageHeader>
                    <PageHeaderTitle>
                        <MdOutlinePendingActions />
                        {t('generic.workflow')}
                    </PageHeaderTitle>
                </PageHeader>
                <CategoriesWorkflowsList
                    onClickAdd={() => openModal(CustomActions.CREATE_WORKFLOW)}
                    onClickUpdate={(workflow: CategoryWorkflow) => {
                        setSelectedCategoryWorkflow(workflow);
                        openModal(CustomActions.UPDATE_WORKFLOW);
                    }}
                    categoryWorkflows={categoryWorkflows.data ?? []}
                />
                <CategoriesWorkflowsAssignation
                    onClickUpdate={(category: CategoryWorkflowAssignment) => {
                        setSelectedCategoryWorkflowAssignment(category);
                        openModal(CustomActions.UPDATE_WORKFLOW_ASSIGNMENT);
                    }}
                    categoryWorkflowsAssignments={categoryWorkflowsAssignments.data ?? []}
                />
            </PageContainer>
        </>
    );
};

export default CategoriesStatusActions;
