import React from 'react';
import { Content } from './CaseOverview-styles';
import { useOutletContext } from 'react-router-dom';
import { CasesPagesContext } from '../CasePagesContext';
import { LocalizedText, PageContainer, PageHeader } from '../../../../components';
import { CaseChildPageTitle } from '../CasePages-styles';
import { BiCategory } from 'react-icons/bi';
import { CaseOverviewDescription, CaseOverviewWorkflows } from './components';

const CaseOverview = () => {
    const { currentCase, refreshCurrentCase } = useOutletContext<CasesPagesContext>();
    return (
        <PageContainer>
            <PageHeader display="row">
                <CaseChildPageTitle>
                    <BiCategory />
                    <LocalizedText text={currentCase.category.title} />
                </CaseChildPageTitle>
            </PageHeader>
            <Content>
                <CaseOverviewDescription currentCase={currentCase} />
                <CaseOverviewWorkflows currentCase={currentCase} refreshCurrentCase={refreshCurrentCase} />
            </Content>
        </PageContainer>
    );
};

export default CaseOverview;
