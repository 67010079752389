import React from 'react';
import { CategoryWorkflow, CategoryWorkflowAssignment } from '../../../../../types';
import { useLocale, usePromises } from '../../../../../hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    Modal,
} from '../../../../../components';
import { formatLocalizedText } from '../../../../../tools';
import { APIService } from '../../../../../services';

type Props = {
    onClose: () => void;
    categoryWorkflows: Array<CategoryWorkflow>;
    refreshCategoriesWorkflowsAssignments: () => Promise<void>;
    selectedCategoryWorkflowAssignment: CategoryWorkflowAssignment;
};

const UpdateCategoryWorkflowAssignmentModal = ({
    onClose,
    categoryWorkflows,
    refreshCategoriesWorkflowsAssignments,
    selectedCategoryWorkflowAssignment,
}: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const [{ error, clearError, executePromise }] = usePromises();

    const validationSchema = Yup.object({
        workflow: Yup.string().required(t('generic.error.required_field')),
    });

    const formik = useFormik({
        initialValues: {
            workflow: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                await APIService.categories().updateCategoryWorkflow(selectedCategoryWorkflowAssignment.id, {
                    workflow: submittedValues.workflow,
                });
                await refreshCategoriesWorkflowsAssignments();
                onClose();
            });
        },
    });

    return (
        <Modal
            title={formatLocalizedText(selectedCategoryWorkflowAssignment.title, locale)}
            onClose={onClose}
            error={error}
            clearError={clearError}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="workflow" expand>
                    <FormGroupLabel>Workflow</FormGroupLabel>
                    <FormSelectInput
                        options={[
                            { label: 'Default', value: '' },
                            ...categoryWorkflows.map((categoryWorkflow) => ({
                                label: categoryWorkflow.name,
                                value: categoryWorkflow.id,
                            })),
                        ]}
                    />
                </FormGroup>
                <FormFooter submitLabel="Update workflow" secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default UpdateCategoryWorkflowAssignmentModal;
