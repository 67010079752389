import React from 'react';
import {
    ActionsList,
    ActionsListItem,
    ActionsPlaceholder,
    Container,
    Status,
} from './CategoriesWorkflowsList-styles';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderContent,
    AccordionHeaderTitle,
    LocalizedText,
    PrimaryContainedAddButton,
    SecondaryOutlinedButton,
} from '../../../../../components';
import { useLoggedInUser } from '../../../../../hooks';
import { CategoryWorkflow } from '../../../../../types';
import { BiPencil } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

type Props = {
    onClickAdd: () => void;
    onClickUpdate: (workflow: CategoryWorkflow) => void;
    categoryWorkflows: Array<CategoryWorkflow>;
};

const CategoriesWorkflowsList = ({ onClickAdd, onClickUpdate, categoryWorkflows }: Props) => {
    const { t } = useTranslation();
    const user = useLoggedInUser();

    return (
        <Container>
            <AccordionContainer triggerUpdate={categoryWorkflows}>
                <Accordion level={0} index={0} parentIndex={0} isToggled>
                    <AccordionHeader>
                        <AccordionHeaderTitle>{t('generic.list')}</AccordionHeaderTitle>
                        {user.hasPermissions('categories.create') && (
                            <AccordionHeaderContent>
                                <PrimaryContainedAddButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClickAdd();
                                    }}
                                >
                                    {t('generic.add')}
                                </PrimaryContainedAddButton>
                            </AccordionHeaderContent>
                        )}
                    </AccordionHeader>
                    <AccordionBody>
                        {categoryWorkflows.map((categoryWorkflow, categoryWorkflowIndex) => (
                            <Accordion key={categoryWorkflow.id} level={1} index={categoryWorkflowIndex}>
                                <AccordionHeader>
                                    <AccordionHeaderTitle>
                                        {categoryWorkflow.name} {categoryWorkflow.isDefault && ' (Default)'}
                                    </AccordionHeaderTitle>
                                    {user.hasPermissions('categories.update') && (
                                        <AccordionHeaderContent>
                                            <SecondaryOutlinedButton
                                                leadingIcon={<BiPencil />}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onClickUpdate(categoryWorkflow);
                                                }}
                                            />
                                        </AccordionHeaderContent>
                                    )}
                                </AccordionHeader>
                                <AccordionBody>
                                    {categoryWorkflow.workflow
                                        .filter((workflow) => workflow.isEnabled === true)
                                        .map((workflow, workflowIndex) => (
                                            <Accordion
                                                level={2}
                                                index={workflowIndex}
                                                parentIndex={categoryWorkflowIndex}
                                            >
                                                <AccordionHeader>
                                                    <Status>
                                                        <LocalizedText text={workflow.status.locale} /> (
                                                        {workflow.actions.length})
                                                    </Status>
                                                </AccordionHeader>
                                                <AccordionBody>
                                                    {workflow.actions.length === 0 ? (
                                                        <ActionsPlaceholder>
                                                            {t('generic.no_actions')}
                                                        </ActionsPlaceholder>
                                                    ) : (
                                                        <ActionsList>
                                                            {workflow.actions.map((action) => (
                                                                <ActionsListItem key={action.internalId}>
                                                                    <LocalizedText text={action.title} />
                                                                </ActionsListItem>
                                                            ))}
                                                        </ActionsList>
                                                    )}
                                                </AccordionBody>
                                            </Accordion>
                                        ))}
                                </AccordionBody>
                            </Accordion>
                        ))}
                    </AccordionBody>
                </Accordion>
            </AccordionContainer>
        </Container>
    );
};

export default CategoriesWorkflowsList;
