import React from 'react';
import { Container } from './CategoriesWorkflowsAssignation-styles';
import {
    Accordion,
    AccordionBody,
    AccordionContainer,
    AccordionHeader,
    AccordionHeaderTitle,
    Table,
    TableBodyRowAction,
    TableColumnConfig,
} from '../../../../../components';
import { useLocale, useLoggedInUser, useTable } from '../../../../../hooks';
import { formatLocalizedText } from '../../../../../tools';
import { CategoryWorkflowAssignment } from '../../../../../types';
import { useTranslation } from 'react-i18next';

type Props = {
    onClickUpdate: (category: CategoryWorkflowAssignment) => void;
    categoryWorkflowsAssignments: Array<CategoryWorkflowAssignment>;
};

const CategoriesWorkflowsAssignation = ({ onClickUpdate, categoryWorkflowsAssignments }: Props) => {
    const user = useLoggedInUser();
    const locale = useLocale();
    const { t } = useTranslation();

    const columns: Array<TableColumnConfig<CategoryWorkflowAssignment>> = [
        {
            id: 'title',
            headerTitle: 'generic.category',
            getValue: (category) => formatLocalizedText(category.title, locale),
            width: '1fr',
            isSortable: true,
        },
        {
            id: 'workflow',
            headerTitle: 'generic.workflow',
            getValue: (category) =>
                category.workflow.name != null
                    ? `${category.workflow.name} ${category.workflow.isDefault ? ' (Default)' : ''}`
                    : 'N/A',
            width: '1fr',
        },
        {
            id: 'action',
            headerTitle: '',
            getValue: () => '',
            width: '80px',
            isDisplayed: user.hasPermissions('categories.create'),
            getDisplayedValue: (category) => (
                <TableBodyRowAction
                    positionListAtTop={true}
                    actions={[
                        {
                            label: t('generic.update'),
                            onClick: () => onClickUpdate(category),
                        },
                    ]}
                />
            ),
        },
    ];

    const { data, displayedColumns, handleSort, sortedColumn, isSortingDown } = useTable(
        categoryWorkflowsAssignments,
        columns
    );

    return (
        <Container>
            <AccordionContainer triggerUpdate={categoryWorkflowsAssignments}>
                <Accordion level={0} index={0} parentIndex={0} isToggled>
                    <AccordionHeader>
                        <AccordionHeaderTitle>{t('generic.assignment')}</AccordionHeaderTitle>
                    </AccordionHeader>
                    <AccordionBody>
                        <Table
                            columns={displayedColumns}
                            data={data}
                            handleSort={handleSort}
                            sortedColumn={sortedColumn}
                            isSortingDown={isSortingDown}
                        />
                    </AccordionBody>
                </Accordion>
            </AccordionContainer>
        </Container>
    );
};

export default CategoriesWorkflowsAssignation;
