import styled from 'styled-components';

export const Container = styled.div`
    padding: ${({ theme }) => theme.box.spacing.lg};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    width: 100%;
    height: max-content;
`;

export const Title = styled.div`
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const List = styled.ul``;

export const ListItem = styled.li`
    &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.box.spacing.md};
    }
`;

export const ListItemLabel = styled.div`
    display: flex;
    align-items: center;
`;

export const ListItemLabelBox = styled.div<{ isCompleted: boolean }>`
    margin-right: ${({ theme }) => theme.box.spacing.md};
    font-size: ${({ theme }) => theme.font.size.sm};
    padding: ${({ theme }) => `${theme.box.spacing.sm} ${theme.box.spacing.md}`};
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    background: ${({ theme }) => theme.colors.primary.extended_10};
    color: ${({ theme }) => theme.colors.primary.main};
    border-radius: 10px;
    font-weight: ${({ theme }) => theme.font.weight.semiBold};

    ${({ isCompleted, theme }) => {
        if (isCompleted === true) {
            return {
                borderColor: theme.colors.green.main,
                color: theme.colors.green.main,
                background: theme.colors.green.extended_10,
            };
        }
    }}
`;

export const ListItemLabelText = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
`;

export const Placeholder = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    font-style: italic;
`;
