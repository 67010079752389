export enum Routes {
    CASES = 'cases',
    CASES_LIST = '',
    CASES_NEW = 'new',
    CASE_OVERVIEW = '',
    CASE_QUOTE = 'quote',
    CASE_DOCUMENTS = 'documents',
    CASE_DOCUMENTS_LIST = '',
    CASE_CLIENTS = 'clients',
    CASE_ACCESS_MANAGEMENT = 'access-management',
    CASE_NOTES = 'notes',
    CASE_EVENTS = 'events',
    CASE_FORMS = 'forms',
    CASE_FORMS_LIST = '',
    CASE_FORMS_FILL = 'fill',
    CATEGORIES = 'categories',
    CATEGORIES_LIST = '',
    CATEGORIES_QUOTES_LIST = 'quotes',
    CATEGORIES_FILES_LIST = 'files',
    CATEGORIES_WORKFLOW = 'workflow',

    CLIENTS = 'clients',
    CLIENTS_LIST = '',
    DASHBOARD = '',
    EMPLOYEES = 'employees',
    EMPLOYEES_LIST = '',

    REPORTS = 'reports',
    REPORTS_LIST = '',

    FORMS = 'forms',
    FORMS_LIST = '',
    FORMS_LIST_TABLE = '',
    FORMS_SECTIONS_LIST = 'sections',
    FORMS_QUESTIONS_LIST = 'questions',

    LOGIN = 'login',
    RESET_PASSWORD_REQUEST = 'reset-password/request',
    RESET_PASSWORD_CONFIRM = 'reset-password/confirm',
    ORGANIZATIONS = 'organizations',
    ORGANIZATIONS_LIST = '',
    SETTINGS = 'settings',
    SETTINGS_PROFILE = 'profile',
    SETTINGS_CREDENTIALS = 'credentials',
    SETTINGS_PREFERENCES = 'preferences',
    UUID = 'uuid',
}
