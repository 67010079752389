import { CaseStatus, StatusListItem } from './cases';
import { OCR_TYPE } from './cases-ocr';
import { LocaleObject } from './locale';

// **************************************************************************
// Atoms
// **************************************************************************
export enum CategoryQuoteGroupAuthorization {
    EMPLOYEE = 'employee',
    CLIENT = 'client',
    NONE = 'none',
}

export enum CategoryQuoteType {
    UNIQUE = 'unique',
    MULTIPLE = 'multiple',
}

// **************************************************************************
// Main Categories
// **************************************************************************

export type CategoryLinkedQuote = {
    id: string;
    index: number;
    title: LocaleObject;
    rules: {
        quoteType: CategoryQuoteType;
        belongsToClient: boolean;
        hasDependent: boolean;
        maxCount?: number;
    };
    prices: {
        quote: {
            value: number;
        };
        dependent?: {
            value: number;
        };
    };
};

export type Category = {
    id: string;
    title: LocaleObject;
    description: LocaleObject;
    internalId: string;
    quotes: Array<CategoryLinkedQuote>;
};

export type CategoryPayload = {
    title: LocaleObject;
    description: LocaleObject;
    internalId: string;
    quotes: Array<{
        id: string;
        index: number;
        type: CategoryQuoteType;
        maxCount?: number;
        price: number;
        dependentPrice?: number;
    }>;
};

export type CategoryWorkflowAssignment = {
    id: string;
    title: LocaleObject;
    internalId: string;
    workflow: {
        id: string;
        name: string;
        isDefault: boolean;
    };
};

// **************************************************************************
// Quotes
// **************************************************************************

export type CategoryQuote = {
    id: string;
    title: LocaleObject;
    belongsToClient: boolean;
    fileGroups: Array<{
        title: LocaleObject;
        authorization: CategoryQuoteGroupAuthorization;
        files: Array<{
            id: string;
            title: LocaleObject;
            index: number;
        }>;
    }>;
    dependent?: {
        fileGroups: Array<{
            title: LocaleObject;
            authorization: CategoryQuoteGroupAuthorization;
            files: Array<{
                id: string;
                title: LocaleObject;
                index: number;
            }>;
        }>;
    };
};

export type CategoryQuotePayload = {
    title: LocaleObject;
    belongsToClient: boolean;
    fileGroups: Array<{
        title: LocaleObject;
        authorization: CategoryQuoteGroupAuthorization;
        files: Array<{ id: string; index: number }>;
    }>;
    dependentFileGroups?: Array<{
        title: LocaleObject;
        authorization: CategoryQuoteGroupAuthorization;
        files: Array<{ id: string; index: number }>;
    }>;
};

// **************************************************************************
// Files
// *************************************************************************
export type CategoryFile = {
    id: string;
    title: {
        en: string;
        fr: string;
    };
    description: {
        en: string;
        fr: string;
    };
    ocr: {
        type: OCR_TYPE | null;
    };
};

// **************************************************************************
// Workflows
// *************************************************************************

export type CategoryWorkflow = {
    id: string;
    name: string;
    isDefault: boolean;
    workflow: Array<CategoryWorkflowItem>;
};

export type CategoryWorkflowItem = {
    status: StatusListItem;
    isEnabled: boolean;
    actions: Array<CategoryWorkflowItemAction>;
};

export type CategoryWorkflowItemAction = {
    title: LocaleObject;
    internalId: string;
};

export type CategoryWorkflowPayload = {
    name: string;
    isDefault: boolean;
    workflow: Array<{
        status: CaseStatus;
        isEnabled: boolean;
        actions: Array<{
            title: LocaleObject;
            internalId?: string;
        }>;
    }>;
};
