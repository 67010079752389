import React from 'react';
import {
    Action,
    ActionsList,
    AddAction,
    AddActionLabel,
    Container,
    RemoveAction,
    Status,
} from './WorkflowFormItem-styles';
import { CheckboxInput, LocalizedText, TextInput } from '../../../../../../components';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CaseStatus, Locale } from '../../../../../../types';
import { WorkflowFormItemType } from '../WorkflowForm';

type Props = {
    item: WorkflowFormItemType;
    isUpdating: boolean;
    onChangeAction: (status: CaseStatus, index: number, locale: Locale, value: string) => void;
    onChangeStatus: (status: CaseStatus, isEnabled: boolean) => void;
    onAddAction: (status: CaseStatus) => void;
    onRemoveAction: (status: CaseStatus, index: number) => void;
};

const WorkflowFormItem = ({
    item,
    isUpdating,
    onChangeAction,
    onAddAction,
    onRemoveAction,
    onChangeStatus,
}: Props) => {
    return (
        <Container>
            <Status>
                {isUpdating !== true && (
                    <CheckboxInput
                        isChecked={item.isEnabled}
                        onChange={(value) => onChangeStatus(item.status.id, value)}
                    />
                )}
                <LocalizedText text={item.status.locale} />
            </Status>
            <ActionsList>
                {item.actions.map((action, index) => {
                    const isNotRemovable = index === 0 && item.actions.length === 1;

                    return (
                        <Action key={index}>
                            <TextInput
                                label="EN"
                                value={action.title_en}
                                onChange={(e) =>
                                    onChangeAction(item.status.id, index, Locale.EN, e.target.value)
                                }
                                leadingIcon={
                                    <RemoveAction isDisabled={isNotRemovable}>
                                        <AiOutlineCloseCircle
                                            onClick={() => {
                                                if (isNotRemovable === true) {
                                                    return;
                                                }

                                                onRemoveAction(item.status.id, index);
                                            }}
                                        />
                                    </RemoveAction>
                                }
                            />
                            <TextInput
                                label="FR"
                                value={action.title_fr}
                                onChange={(e) =>
                                    onChangeAction(item.status.id, index, Locale.FR, e.target.value)
                                }
                            />
                        </Action>
                    );
                })}
            </ActionsList>

            <AddAction>
                <AddActionLabel onClick={() => onAddAction(item.status.id)}>Add action</AddActionLabel>
            </AddAction>
        </Container>
    );
};

export default WorkflowFormItem;
