import styled, { css } from 'styled-components';

const heightStyles = css`
    width: 100%;
    height: ${({ theme }) =>
        `calc(100vh - ${theme.config.topbar.height.default}px - ${theme.box.spacing.xxl})`};
    max-height: ${({ theme }) =>
        `calc(100vh - ${theme.config.topbar.height.default}px - ${theme.box.spacing.xxl})`};
`;

export const Wrapper = styled.div`
    width: 100%;
    ${heightStyles};
    overflow: hidden;

    .page-container {
        ${heightStyles};
    }
`;

export const Container = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colors.background.secondary};
    padding: ${({ theme }) => theme.box.spacing.sm};
    border-radius: 4px;
    grid-column: 1 / -1 !important;
    position: relative;

    flex: 1;
    overflow-y: hidden;
    overflow-x: scroll;
`;

export const Placeholder = styled.div<{ left: number; height: number }>`
    position: absolute;
    width: 22rem;
    top: ${({ theme }) => `calc(${theme.box.spacing.sm} * 2)`};
    left: ${({ left }) => `${left}px`};
    height: ${({ height }) => `${height}px`};
    background-color: ${({ theme }) => theme.colors.primary.extended_10};
    border-radius: 4px;
`;

export const NoStatusSelected = styled.div`
    font-size: ${({ theme }) => theme.font.size.md};
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NoStatusSelectedLabel = styled.div`
    background: ${({ theme }) => theme.colors.surface.main};
    padding: ${({ theme }) => `${theme.box.spacing.md} ${theme.box.spacing.xl}`};
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
`;
