import React, { useState } from 'react';
import { usePromises, useStore } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CategoryWorkflowPayload, Locale } from '../../../../../types';
import { APIService } from '../../../../../services';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    FormTextInput,
    Modal,
    ModalSize,
} from '../../../../../components';
import { WorkflowForm, WorkflowFormItemType } from '../components';

type Props = {
    onClose: () => void;
    refreshCategoriesWorkflows: () => Promise<void>;
    refreshCategoriesWorkflowsAssignments: () => Promise<void>;
};

const CreateWorkflowModal = ({
    onClose,
    refreshCategoriesWorkflows,
    refreshCategoriesWorkflowsAssignments,
}: Props) => {
    const { t } = useTranslation();
    const { statusList } = useStore().caseData;

    const [{ error, clearError, executePromise }] = usePromises();
    const [workflowForm, setWorkflowForm] = useState<Array<WorkflowFormItemType>>(
        statusList.map((status) => ({
            status: status,
            isEnabled: true,
            actions: [
                {
                    title_en: '',
                    title_fr: '',
                },
            ],
        }))
    );

    const validationSchema = Yup.object({
        name: Yup.string().required(t('generic.error.required_field')),
        isDefault: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            isDefault: 'no',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload: CategoryWorkflowPayload = {
                    name: submittedValues.name,
                    isDefault: submittedValues.isDefault === 'yes',
                    workflow: workflowForm.map((workflowFormItem) => ({
                        status: workflowFormItem.status.id,
                        isEnabled: workflowFormItem.isEnabled,
                        actions: workflowFormItem.actions
                            .filter((action) => action.title_en.length > 0)
                            .map((action) => ({
                                title: {
                                    [Locale.EN]: action.title_en,
                                    [Locale.FR]: action.title_fr,
                                },
                            })),
                    })),
                };
                await APIService.categories().createWorkflow(payload);
                await Promise.all([refreshCategoriesWorkflows(), refreshCategoriesWorkflowsAssignments()]);
                onClose();
            });
        },
    });

    return (
        <Modal
            title="Add workflow"
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="name">
                    <FormGroupLabel>{t('generic.name')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="isDefault">
                    <FormGroupLabel>Is Default</FormGroupLabel>
                    <FormSelectInput
                        options={[
                            { label: t('generic.yes'), value: 'yes' },
                            { label: t('generic.no'), value: 'no' },
                        ]}
                    />
                </FormGroup>
                <WorkflowForm form={workflowForm} setForm={setWorkflowForm} />
                <FormFooter submitLabel="Add workflow" secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default CreateWorkflowModal;
