import React from 'react';
import { Case } from '../../../../../../types';
import { Container } from './CaseOverviewDescription-styles';
import { useLocale } from '../../../../../../hooks';
import { TextEditorInput } from '../../../../../../components';
import { formatLocalizedText } from '../../../../../../tools';

type Props = {
    currentCase: Case;
};

const CaseOverviewDescription = ({ currentCase }: Props) => {
    const locale = useLocale();

    return (
        <Container>
            <TextEditorInput
                initialValue={formatLocalizedText(currentCase.category.description, locale)}
                isDisabled
            />
        </Container>
    );
};

export default CaseOverviewDescription;
