import React, { useState } from 'react';
import { usePromises } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CategoryWorkflow, CategoryWorkflowPayload, Locale } from '../../../../../types';
import { APIService } from '../../../../../services';
import {
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormSelectInput,
    FormTextInput,
    Modal,
    ModalSize,
} from '../../../../../components';
import { WorkflowForm, WorkflowFormItemType } from '../components';

type Props = {
    onClose: () => void;
    refreshCategoriesWorkflows: () => Promise<void>;
    refreshCategoriesWorkflowsAssignments: () => Promise<void>;
    selectedCategoryWorkflow: CategoryWorkflow;
};

const UpdateCategoryWorkflowModal = ({
    onClose,
    refreshCategoriesWorkflows,
    refreshCategoriesWorkflowsAssignments,
    selectedCategoryWorkflow,
}: Props) => {
    const { t } = useTranslation();

    const [{ error, clearError, executePromise }] = usePromises();
    const [workflowForm, setWorkflowForm] = useState<Array<WorkflowFormItemType>>(
        selectedCategoryWorkflow.workflow.map((workflow) => ({
            status: workflow.status,
            isEnabled: workflow.isEnabled,
            actions: workflow.actions.map((action) => ({
                title_en: action.title[Locale.EN],
                title_fr: action.title[Locale.FR],
                internalId: action.internalId,
            })),
        }))
    );

    const validationSchema = Yup.object({
        name: Yup.string().required(t('generic.error.required_field')),
        isDefault: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            name: selectedCategoryWorkflow.name,
            isDefault: selectedCategoryWorkflow.isDefault ? 'yes' : 'no',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                const payload: CategoryWorkflowPayload = {
                    name: submittedValues.name,
                    isDefault: submittedValues.isDefault === 'yes',
                    workflow: workflowForm.map((workflowFormItem) => ({
                        isEnabled: workflowFormItem.isEnabled,
                        status: workflowFormItem.status.id,
                        actions: workflowFormItem.actions
                            .filter((action) => action.title_en.length > 0)
                            .map((action) => ({
                                title: {
                                    [Locale.EN]: action.title_en,
                                    [Locale.FR]: action.title_fr,
                                },
                                internalId: action.internalId,
                            })),
                    })),
                };
                await APIService.categories().updateWorkflow(selectedCategoryWorkflow.id, payload);
                await Promise.all([refreshCategoriesWorkflows(), refreshCategoriesWorkflowsAssignments()]);
                onClose();
            });
        },
    });

    return (
        <Modal
            title="Update workflow"
            onClose={onClose}
            error={error}
            clearError={clearError}
            size={ModalSize.LARGE}
        >
            <Form formik={formik} validationSchema={validationSchema}>
                <FormGroup name="name">
                    <FormGroupLabel>{t('generic.name')}</FormGroupLabel>
                    <FormTextInput type="text" />
                </FormGroup>
                <FormGroup name="isDefault">
                    <FormGroupLabel>Is Default</FormGroupLabel>
                    <FormSelectInput
                        options={[
                            { label: t('generic.yes'), value: 'yes' },
                            { label: t('generic.no'), value: 'no' },
                        ]}
                    />
                </FormGroup>
                <WorkflowForm isUpdating={true} form={workflowForm} setForm={setWorkflowForm} />
                <FormFooter submitLabel="Update workflow" secondary={{ onClick: onClose }} />
            </Form>
        </Modal>
    );
};

export default UpdateCategoryWorkflowModal;
